import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Button, Checkbox, Field, FieldSet, Form, HorizontalGroup, InfoBox, Input, LoadingPlaceholder, Modal, RadioButtonGroup, TextArea } from '@grafana/ui';
import { } from '@emotion/core';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import moment from 'moment';
import { getDataSourceSrv as getDataSourceService, loadPluginCss } from '@grafana/runtime';
import axios from 'axios';

interface Props extends PanelProps<SimpleOptions> { }

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  loadPluginCss({
    dark: 'plugins/maintenance-request-app/css/base.css',
    light: 'plugins/maintenance-request-app/css/base.css',
  });

  const priorityOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
  ];
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [fromTimestamp, setFromDate] = React.useState(moment(new Date()) as Moment | string);
  const [untilTimestamp, setUntilDate] = React.useState(moment(new Date()) as Moment | string);
  const [priorityVal, setPriorityVal] = React.useState(3 as number | undefined);
  const [sensorId, setSensorId] = React.useState('');
  const [shortDescription, setShortDescription] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);
  const [proxyUrl, setProxyUrl] = React.useState('');
  const [response, setResponse] = React.useState({ ok: undefined as any, error: undefined as any });
  const [isShutdown, setIsShutdown] = React.useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    clearForm();
    setSensorId(replaceVariables(options.sensorText));
    setModalIsOpen(true);
    getProxyURL();
  }

  const onSubmit = () => {
    setIsSending(true);
    const data = {
      sensorId: sensorId,
      priority: priorityVal,
      shortDescription: shortDescription,
      description: description,
      from: fromTimestamp,
      to: untilTimestamp,
      isShutdown: isShutdown
    };
    console.log(JSON.stringify(data, null));

    const URL = window.location.origin + proxyUrl + '/publicapi/api/maintenance/submit/';

    axios
      .post(URL, data)
      .then(response => {
        console.log(response);
        setResponse({ ok: response, error: undefined });
        setIsSending(false);
      })
      .catch(e => {
        console.log(e);
        setResponse({ ok: undefined, error: e });
        setIsSending(false);
      });
  }

  const clearForm = () => {
    setIsSending(false);
    setIsShutdown(false);
    setSensorId('');
    setPriorityVal(3);
    setShortDescription('');
    setDescription('');
    setFromDate(moment(new Date()));
    setUntilDate(moment(new Date()));
    setResponse({ ok: undefined, error: undefined });
  }

  const renderForm = () => {
    if (isSending) {
      return (<LoadingPlaceholder text="Sending..." />);
    }

    if (response.ok !== undefined) {
      if (response.ok.data) {
        var obj = JSON.parse(response.ok.data);
        return (<InfoBox title="Success"
          severity='success'
          onDismiss={closeModal}
        >{<div>The order was created with number <b>{obj.nr}</b></div>}</InfoBox>);
      }

      return (<InfoBox title="Success"
        severity='success'
        onDismiss={closeModal}
      >{<div>Missing information, check the console for more details.</div>}</InfoBox>);
    }

    if (response.error !== undefined) {
      if (response.error.response?.data) {
        return (<InfoBox title="Error"
          severity='error'
          onDismiss={closeModal}
        >{<div>{response.error.response.data}</div>}</InfoBox>);
      }
      return (<InfoBox title="Error"
        severity='error'
        onDismiss={closeModal}
      >{<div>Check the console for more details!</div>}</InfoBox>);
    }

    return (
      <Form onSubmit={onSubmit}>
        {({ register, errors }) => (
          <>
            <FieldSet disabled={isSending}>
              <HorizontalGroup  >
                <Field label="Asset" required={true}>
                  <Input name="Asset" value={sensorId} onChange={(e: any) => setSensorId(e.target.value)} ref={register({ required: true })} />
                </Field>
                <Field label="Priority">
                  <RadioButtonGroup
                    options={priorityOptions}
                    value={priorityVal}
                    onChange={setPriorityVal}
                  />
                </Field>
                <Field label=" ">
                  <Checkbox
                    value={isShutdown}
                    onChange={(e: any) => setIsShutdown(e.target.checked)}
                    label="Shutdown required"
                  />
                </Field>
              </HorizontalGroup>
              <Field label="Short Description" required={true}>
                <Input name="Short Description" value={shortDescription} onChange={(e: any) => setShortDescription(e.target.value)} ref={register({ required: true })} />
              </Field>
              <Field label="Description">
                <TextArea name="Description" value={description} onChange={(e: any) => setDescription(e.target.value)} />
              </Field>
              <HorizontalGroup  >
                <Field label="From" required={true}>
                  <Datetime
                    value={fromTimestamp}
                    open={false}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    onChange={setFromDate}
                  />
                </Field>
                <Field label="Until" required={true}>
                  <Datetime
                    value={untilTimestamp}
                    open={false}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    onChange={setUntilDate}
                  />
                </Field>
              </HorizontalGroup>
            </FieldSet>
            <HorizontalGroup  >
              <Button variant="primary" disabled={isSending}>Save</Button>
              <Button variant="secondary" onClick={closeModal}>Close</Button>
            </HorizontalGroup>
          </>
        )
        }
      </Form >
    );
  }

  const getProxyURL = () => {
    const { publicApiText } = options;
    if (proxyUrl !== '') {
      return;
    }
    const srv = getDataSourceService();
    srv.get(publicApiText).then(result => {
      const us = result as any;
      setProxyUrl(`/api/datasources/${us.id}/resources`);
    });
  }

  return (
    <div  >
      <div className={"mmsr-main-btn"}>
        <Button onClick={() => openModal()} title={options.mainBtnText} variant="secondary" >
          <i className="fas fa-wrench" style={{ marginRight: "5px" }}></i>{options.mainBtnText}
        </Button>
      </div>

      <Modal title="Maintenance request" isOpen={modalIsOpen} onDismiss={closeModal}>
        {renderForm()}
      </Modal>
    </div>
  );
};
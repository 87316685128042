import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      path: 'mainBtnText',
      name: 'Button text',
      description: 'Text for maintenance request button',
      defaultValue: 'Maintenance',
    }).addTextInput({
      path: 'sensorText',
      name: 'Sensor',
      description: 'Sensor ID (or Sensor ID variable name)',
      defaultValue: '$sensor',
    }).addTextInput({
      path: 'publicApiText',
      name: 'Public API',
      description: 'Public API datasource name',
      defaultValue: 'public-api',
    });
});